import { request, get } from "./ApiService";
import Constants from "../shared/Constants";
import { queryParamParser } from "../shared/utils";

const {
  apiEndPoints: { endPointUrl: endPoint = {} },
  HTTP_STATUS: { OK },
} = Constants;

export async function getSubscriptionsList(params) {
  const url = `${endPoint.SUBSCRIPTIONS}${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  return data;
}

export const getServiceList = async () => {
  const queryParam = [["type", "subscription"]];
  const url = `${endPoint.PUBLIC}/${endPoint.SERVICES}${queryParamParser(queryParam)}`;
  const response = await get(url);
  const { data = [] } = response;
  return data;
};

export const orderGuestSubscription = async (requestData) => {
  const url = `${endPoint.GUEST_SUBSCRIPTION_ORDER}`;
  return request("post", url, requestData).then(response => response.data);
}

export const orderSubscription = async (requestData) => {
  const url = `${endPoint.SUBSCRIPTION}`;
  return request("post", url, requestData).then(response => response.data);
}


export const getCustomers = async () => {
  const url = `${endPoint.PUBLIC}/${endPoint.CUSTOMERS}`;
  return get(url).then((response) => response.data);
};

export const getDetails = async (id) => {
  const url = `${endPoint.SUBSCRIPTIONS}/${id}`;
  return get(url).then((response) => response.data);
};

export const getOrganizations = async (serviceId) => {
  const url = `${endPoint.PUBLIC}/${endPoint.SERVICES}/${serviceId}/${endPoint.ORGANIZATIONS}`;
  return get(url).then((response) => response.data);
};

export const addSubscriptionByAdmin = (data) => {
  const url = `${endPoint.SUBSCRIPTION}`;
  return request("post",url, data).then((response) => response.data);
};

export function updateSubscriptionDetails(id, data) {
  const url = `${endPoint.SUBSCRIPTIONS}/${id}`;
  return request("put",url, data);
}
