import styled from "styled-components";
import {
  typography,
  space,
  color,
  flexbox,
  layout,
  background,
  position,
  compose,
  border,
  shadow,
} from "styled-system";
import Colors from "./Colors";
import Theme from "./Theme";

export const Span = styled.span`
  ${compose(typography, border, space, color, layout, background, position, flexbox, shadow)};
  cursor: ${({ useCursorPointer, cursor }) => (useCursorPointer ? "pointer" : (cursor || "inherit"))};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  opacity: ${({ disable, opacity }) => opacity || (disable ? 0.5 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  &:hover {
    text-decoration: ${({ textDecoration, showHoverEffect }) => (showHoverEffect ? "underline" : textDecoration || "none")};
  }
`;

export const InputBox = styled.input`
  ${compose(typography, border, space, color, flexbox, layout, background, position)};
  outline: 0;
  height: 48px;

  &[type=textarea] {
    height: 100px;
    position: relative;
    bottom: 23%;
  }

  &::-webkit-input-placeholder {
    color: ${Theme.colors.text.black};
  }
`;
InputBox.defaultProps = {
  fontFamily: Theme.fonts.regular,
  lineHeight: Theme.lineHeights.normal,
  fontSize: Theme.fontSizes.regular,
  color: Theme.colors.text.black,
  borderRadius: Theme.radii.regular,
  border: Theme.borders.none,
  p: Theme.space.s,
  width: "100%",
  // width: Theme.widths.fillAvailable,
  bg: Theme.colors.transparent,
};

export const InputArea = styled.textarea`
  ${compose(typography, border, space, color, flexbox, layout, background, position)};
  height: 116px;
  outline: 0;
  resize: none;
  &::-webkit-input-placeholder {
    color: ${Theme.colors.text.black};
  }
  &::-moz-placeholder {
    color: ${Theme.colors.text.black};
  }
`;
InputArea.defaultProps = {
  fontFamily: Theme.fonts.regular,
  lineHeight: Theme.lineHeights.normal,
  fontSize: Theme.fontSizes.regular,
  color: Theme.colors.text.black,
  borderRadius: Theme.radii.regular,
  border: Theme.borders.none,
  p: Theme.space.s,
  width: Theme.widths.fillAvailable,
  height: "48px",
  bg: Theme.colors.transparent,
};

export const P = styled("p")(compose(typography, border, space, color, layout, background, position, flexbox));

export const Div = styled.div`
  ${compose(typography, border, space, color, flexbox, layout, background, position, shadow)};
  cursor: ${({ cursor }) => cursor || "inherit"};
  opacity: ${({ disable, opacity }) => opacity || (disable ? 0.5 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  @media(max-width:570px){
    &.faheZF {position: static !important}
  }
`;

export const CurvedDiv = styled(Div)`
  border-top-right-radius: 100px;
  z-index: 5;
  background-color: #ffffff;
  width: calc(100% - 165px);
  @media (max-width: 575px) {
    width: calc(97% - 70px);
  }
`;

export const EditDogSpan = styled.span`
  ${compose(typography, border, space, color, layout, background, position, flexbox, shadow)};
  cursor: ${({ useCursorPointer, cursor }) => (useCursorPointer ? "pointer" : (cursor || "inherit"))};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  opacity: ${({ disable, opacity }) => opacity || (disable ? 0.5 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  &:hover {
    text-decoration: ${({ textDecoration, showHoverEffect }) => (showHoverEffect ? "underline" : textDecoration || "none")};
  }
  font-family: 'Rubik-Medium';
`;

export const Ul = styled("ul")(compose(typography, border, space, color, flexbox, layout, background, position));

export const ListItem = styled.li`
  ${compose(typography, border, space, color, flexbox, layout, background, position)};
  list-style: none;
`;

export const Link = styled.a`
  ${compose(typography, border, space, color, flexbox, layout, background, position, shadow)};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  cursor: ${({ disable }) => (disable ? "inherit" : "pointer")};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  &:hover {
    text-decoration: ${({ textDecoration }) => textDecoration || "none"};
    color: ${Colors.Black};
  }
`;

export const Box = styled(Div)`
  display: flex;
  justify-content:center;
  cursor: ${({ cursor }) => cursor || "inherit"};
`;

export const MDImage = styled.img`
${compose(typography, space, border, color, flexbox, layout, background, position)};
  height: ${({ type }) => (type === "calendarCard" ? "35px" : "")};
  cursor: ${({ useCursorPointer, cursor }) => (useCursorPointer ? "pointer" : (cursor || "inherit"))};
`;

export const IsDesktop = styled(Div)`
  display: block;
  @media (max-width: 570px) {
    display: none;
  }
`;

export const IsMobile = styled(Div)`
  display: none;
  @media (max-width: 570px) {
    display: block;
  }
`;

export const Button = styled.button`
  ${compose(typography, space, color, flexbox, layout, background, position, shadow)};
`;
export const MDLabelWrapper = styled(Button)`
  font-family: Rubik-Medium;
  font-weight: 500;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  align-self: center;
  outline: none;
  height: ${({ height }) => height || "25px"};
  color: ${({ textColor }) => textColor || `${Colors.Black}`};
  background-color: ${({ backgroundColor }) => backgroundColor || `${Colors.Orange}`};
  cursor: inherit;
  border: none;
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
`;

export const MDLabel = styled(Span)`
  ${compose(typography, flexbox, space )};
  float: ${({ float }) => float || "start"};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  cursor: ${({ useCursorPointer, cursor }) => (useCursorPointer ? "pointer" : (cursor || "inherit"))};
  word-break: ${({ wordBreak }) => (wordBreak || "normal")};
  white-space: ${({ whiteSpace }) => (whiteSpace || "inherit")};
  text-transform: ${({ textTranform }) => (textTranform || "none")};
  `;
MDLabel.defaultProps = {
  display: "flex",
  fontFamily: Theme.fonts.semibold,
  fontStyle: "normal",
  fontWeight: Theme.fontWeights.medium,
  fontSize: Theme.fontSizes.small,
  lineHeight: "17px",
  textAlign: "left",
  alignSelf: "center",
};

export const Header = styled(MDLabel)`
  line-height: 43px;
  @media (max-width: 570px) {
    line-height: 33px;
  }
`;
Header.defaultProps = {
  fontSize: Theme.fontSizes.header,
};

export const MDInputValue = styled(MDLabel)`
  font-family: Roboto;
  font-weight: inherit;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  color: ${({ placeholder }) => (placeholder ? `${Colors.DarkGrey}` : `${Colors.Black}`)};
  word-break: ${({ wordBreak }) => (wordBreak || "normal")};
`;

export const MDPaginationLabel = styled(MDLabel)`
  display: initial;
  padding: 10px;
  color: ${({ navButtons }) => (navButtons ? `${Colors.DarkGrey}` : `${Colors.Black}`)};
  font-size: 16px;
  font-family: Roboto;
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  cursor: ${({ disable, cursor }) => cursor || (disable ? "inherit" : "pointer")};
  @media (max-width: 570px) {
    padding: 8px;
  }
`;

export const RelativeContainer = styled(Div)`
  position: relative;
`;

export const AvtarIcon = styled.img`
  height: ${({ isMyAccount }) => (isMyAccount ? "40px" : "50px")};
  width:  ${({ isMyAccount }) => (isMyAccount ? "40px" : "50px")};
  object-fit: cover;
  border-radius: ${({ borderRadius }) => borderRadius || "50%"};
  @media (max-width: 570px) {
    width: 40px;
    height: 40px;
  }
`;

export const Tabcontainer = styled(Div)`
margin: 0;
@media (max-width: 570px) {
  margin: auto;
  width: 100%;
}`;
Tabcontainer.defaultProps = {
  bg: Colors.White,
  borderRadius: Theme.radii.medium,
  height: "50px",
  width: Theme.widths.fitContent,
  display: "flex",
};

export const Tab = styled(Tabcontainer)`
  min-width: 180px;
  background-color: ${({ active }) => (active ? `${Colors.Orange}` : `${Colors.White}`)};
  text-align: center;
  display: grid;
  cursor: pointer;
  @media (max-width: 570px) {
    min-width: 45%;
  }
`;

export const Card = styled(Div)`
  ${compose(position)};
  bottom: ${({ popover }) => (popover ? "56px" : "auto")};
  left: ${({ popover }) => (popover ? "calc(100% - 64px)" : "auto")};
  position: ${({ popover }) => (popover ? "absolute" : "initial")};
  border: ${({ isSelected }) => (isSelected ? `3px solid ${Colors.Primary}` : "none")};
  width: ${({ width, addNewEventCard }) => (addNewEventCard ? "410px" : width)};

  &:hover {
    height: ${({ type, height }) => (type === "calendarCard" ? "auto" : height || "")};
  }
  @media (max-width: 375px) {
    width: 290px !important;
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 32px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: ${({ popover }) => (popover ? `18px solid ${Colors.White}` : "none")};
  }
`;
Card.defaultProps = {
  width: "410px",
  boxShadow: Theme.shadows.medium,
  fontFamily: Theme.fonts.regular,
  bg: Colors.White,
  display: "block",
  m: 0,
  borderRadius: Theme.radii.medium,
};

export const CalendarCardWrapper = styled(Card)`
  width: inherit;
  height: inherit;
  border-radius: 5px;
`;

export const CardHeader = styled(Div)`
  background-color: ${Colors.Secondary};
  height: ${({ height }) => height || "60px"};
  text-align: center;
  align-self: center;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  color: ${Colors.Black};
`;
export const SideMenuPanel = styled.div`
  max-width: 192px !important;
`;
export const MainPanel = styled.div`
  width: calc(100% - 192px);
  height: calc(100vh - 70px);
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${Colors.DarkWhite};
  @media (max-width: 576px) {
    width: 100%;
    height: calc(100% - 70px);
    left: 0;
    &.faheZF {position: static !important}
  }
`;
export const LogInPanel = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${Colors.DarkWhite};
`;
export const FormWrapper = styled(Div)`
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;
FormWrapper.defaultProps = {
  width: "540px",
};
export const Row = styled(Div)`
  display: flex;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
export const ContentLeft = styled(Div)`
  padding-right: 15px;
  @media (max-width: 992px) {
    padding-right: 0px;
    width: 100%;
  }
`;
ContentLeft.defaultProps = {
  width: "50%",
};

export const ContentRight = styled(Div)`
  padding-left: 15px;
  @media (max-width: 992px) {
    padding-left: 0px;
    width: 100%;
  }
`;
ContentRight.defaultProps = {
  width: "50%",
};

export const AccordionWrapper = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 10px;
    padding-top: 5%;
    text-align: center;
`;

export const AccordionContentWrapper = styled.div`
    width: 100%;
    max-height: ${(props) => (props.open ? "fit-content" : "0")};
    transition: all 0.4s ease-in-out;
    overflow: hidden;
`;

export const StyledDiv = styled(Div)`
  content: '';
  background: white;
  border-radius: 10px;
  left: 66%;
  bottom: 200%;
  z-index: 999;
  @media (max-width: 768px) {
    left: 0%;
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 32px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid white;
    @media (max-width: 768px) {
      left: 57%;
    }
  }
`;

export const StyledCardWrapper = styled(Div)`
  ${compose(position)};

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 179px;
    bottom: 21px;
    rotate: 90deg;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid white;
  }
`;
