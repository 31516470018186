import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Tabs from "../../../components/Tabs/Tabs";
import Constants from "../../../shared/Constants";
import { Div, FormWrapper, IsDesktop, IsMobile } from "../../../styles/Styles";
import { useHistory, useParams  } from "react-router-dom";
import PortalContainer from "../../../components/Containers/PortalContainer";
import { getDetails, updateSubscriptionDetails } from "../../../services/SubscriptionService";
import FormLoader from "../../../components/Loader/FormLoader";
import SubscriptionInfoForm from "./SubscriptionInfoForm";
import Button from "../../../components/Button/Button";
import CommonBreadCrumbSection from "../../Common/CommonBreadCrumbSection";
import SubscriptionClientsDogs from "./SubscriptionClientsDogs";
import Modal from "../../../components/Modal/Modal";
import Card from "../../../components/Card/Card";
import { checkRightPermission, getUserInfo } from "../../../shared/utils";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const { language, subscriptionStatus,  icons, themes: {  fontSizes }  } = Constants;

const SubscriptionButtons = ({data,
  selectedTab,
  handleSaveChanges,
  hasWriteAccess,
  isActiveButtonEnabled,
  proceedToActive,
  handleStopSubscriptionChange,
  handleCancelChanges,
  handleActiveTab,
  IsMobile,
  dataToBeUpdated}) => {

  return <Div display="flex" flexDirection={["column","row"]} alignItems="center">
    {data?.status === subscriptionStatus.canceled &&  <>
      {selectedTab === language.label_info && <Button
        primary
        startIcon={icons.AssignmentsMenuIcon}
        IconHeight={fontSizes.medium}
        label={<FormattedMessage id="button_save_changes" defaultMessage="Save changes" />}
        width={[1,200]}
        onClick={handleSaveChanges}
        disabled={!hasWriteAccess}
        className="mr-3"
      />}
    </>}
    {data?.status === subscriptionStatus.active &&  <>
      {selectedTab === language.label_info && <Button
        primary
        startIcon={icons.AssignmentsMenuIcon}
        IconHeight={fontSizes.medium}
        label={<FormattedMessage id="button_save_changes" defaultMessage="Save changes" />}
        width={[1,200]}
        onClick={handleSaveChanges}
        disabled={!isActiveButtonEnabled || !hasWriteAccess}
        className={`mr-3 ${IsMobile ? "mb-3": "mb-0"}`}
      />}
      <Button
        secondary
        startIcon={icons.PrimaryStopIcon}
        IconHeight={fontSizes.medium}
        label={<FormattedMessage id="label_stop_subscription" defaultMessage="Stop subscription" />}
        width={[1,255]}
        disabled={!dataToBeUpdated?.end_date || !dataToBeUpdated?.product_id || !hasWriteAccess}
        className="mr-3"
        onClick={handleStopSubscriptionChange}
      />
    </>}

    {data?.status === subscriptionStatus.application &&  <>
      {selectedTab === language.label_info && !proceedToActive &&<Button
        primary
        startIcon={icons.AssignmentsMenuIcon}
        IconHeight={fontSizes.medium}
        label={<FormattedMessage id="button_save_changes" defaultMessage="Save changes" />}
        width={[1,200]}
        className={`${IsMobile ? "mr-0 mb-3": "mr-3 mb-0"}`}
        disabled={!hasWriteAccess}
        onClick={handleSaveChanges}
      />}
      <Button
        primary
        startIcon={icons.AssignmentsMenuIcon}
        IconHeight={fontSizes.medium}
        label={<FormattedMessage id="label_button_activate" defaultMessage="Activate" />}
        width={[1,200]}
        onClick={handleActiveTab}
        className="mr-md-4"
        disabled={!isActiveButtonEnabled || !hasWriteAccess}
      />

      {proceedToActive && data?.status !== subscriptionStatus.active && <Button
        secondary
        label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
        width={[1,175]}
        className={`${IsMobile ? "mr-0 mt-3": "mr-3 mt-0"}`}
        onClick={handleCancelChanges}
      />}
    </>}
  </Div>
}

const EditSubscription = () => {
  const history = useHistory();
  let { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const tabs = [language.label_info, language.client_and_dog];
  const [selectedTab, setSelectedTab]= useState(language.label_info);
  const [proceedToActive, setProceedToActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [dataToBeUpdated, setDataToBeUpdated] = useState({});
  const [isActiveButtonEnabled, setIsActiveButtonEnabled] = useState(false);
  const userDetails = useSelector((state) => state.AppReducer.userDetails);
  const { permissions = [] } = getUserInfo(userDetails);
  const filterOrgData = permissions.filter((obj)=>obj.organization_id === data?.organization?.id);
  const hasWriteAccess = checkRightPermission(filterOrgData,"is_subscription_editable",Constants.WRITE);

  const fetchDetails = async () => {
    setLoader(true);
    try {
      const { data: responseData = {} } = await getDetails(id);
      const subscriptionData = {...responseData, product: {...responseData.product, details: JSON.parse(responseData.product.details)}}
      setData(subscriptionData);
      window.scrollTo(0,0);
      setDataToBeUpdated(prevData => {
        return {...prevData,
          product_id: subscriptionData.product.id,
          start_date: subscriptionData.start_date,
          end_date: subscriptionData.end_date,
          price: subscriptionData.price || subscriptionData?.product?.details?.price,
        }
      })
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  useEffect(()=>{
    if(data?.status === subscriptionStatus.active){
      setProceedToActive(true);
    }
  },[data?.status])

  useEffect(()=>{
    const itHasNoAccess = filterOrgData[0]?.is_subscription_editable === -1;
    if(itHasNoAccess){
      handleRedirectToSubscriptions();
    }
  },[filterOrgData.length])


  const handleTabs = (tab) => {
    setSelectedTab(tab);
  }

  const handleNextPreviousTabs = (selectedTab) => {
    setSelectedTab(tabs[selectedTab])
  }

  const handleRedirectToSubscriptions = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const pageNo = params.get("page");
    const selectedTab = params.get("tab");
    const {
      location: { state = "" },
    } = history;
    if (pageNo) {
      history.push(`${Constants.routes.subscriptionsList.url}?page=${pageNo}&tab=${selectedTab}`);
    }
    else if(state) {
      history.push(state);
    } else {
      history.goBack();
    }
  };


  const getStatus = (status) => {
    if(status === subscriptionStatus.application){
      return "application";
    } else if(status === subscriptionStatus.canceled){
      return "stopped";
    }
    return status;
  }

  const handleActiveTab = () =>{
    if(proceedToActive) {
      updateSubscriptionData({status: subscriptionStatus.active, ...dataToBeUpdated})
    } else {
      setProceedToActive(true);
    }
  }

  const handleCancelChanges = () =>{
    setProceedToActive(false);
  }

  const updateSubscriptionData = (subscriptionData) =>{
    updateSubscriptionDetails(data.id, subscriptionData).then(() => {
      setOpenModal(true);
      fetchDetails();
      setAlertHeader(language.done);
      setAlertMessage(language.subscription_done);
    })
  }

  const updateDetails = () => {
    fetchDetails();
    setOpenModal(false);
  }


  const handleStopSubscriptionChange = () => {
    updateSubscriptionData({status: "canceled", ...dataToBeUpdated})
  }

  const handleSaveChanges = () => {
    updateSubscriptionData(dataToBeUpdated)
  }

  const updateIsActiveButtonEnabled = (value) => {
    setIsActiveButtonEnabled(value);
  }

  const buttonsProps = {
    data,
    selectedTab,
    handleSaveChanges,
    hasWriteAccess,
    isActiveButtonEnabled,
    proceedToActive,
    handleStopSubscriptionChange,
    handleCancelChanges,
    handleActiveTab,
    dataToBeUpdated
  };

  return (<PortalContainer>
    <FormWrapper className="w-100">
      <CommonBreadCrumbSection
        handleBack={handleRedirectToSubscriptions}
        header={`${Constants.language.label_application}: ${data?.id || ""}`}
        breadCrumbLabel="breadcrumb_back"
        status={getStatus(data?.status)}
      />
      <Div className="my-4">
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={handleTabs}
          showTabArrowsOn={1}
          onNext={handleNextPreviousTabs}
          onPrevious={handleNextPreviousTabs}
        />
      </Div>
      <Div>
        {loader && <FormLoader />}
        {!loader &&
          <Div display="flex" flexDirection="column">
            {selectedTab === language.label_info &&
              <SubscriptionInfoForm
                edit
                subscriptionData={data}
                setSubscriptionData={setData}
                proceedToActive={proceedToActive}
                dataToBeUpdated={dataToBeUpdated}
                setDataToBeUpdated={setDataToBeUpdated}
                updateIsActiveButtonEnabled={updateIsActiveButtonEnabled}
                hasNoWriteAccess={!hasWriteAccess}
              />
            }
            {selectedTab === language.client_and_dog && <SubscriptionClientsDogs subscriptionData={data} />}
            <IsDesktop>
              <SubscriptionButtons {...buttonsProps} />
            </IsDesktop>
            <IsMobile>
              <SubscriptionButtons {...buttonsProps} IsMobile />
            </IsMobile>
          </Div>}
      </Div>
      {openModal && (
        <Modal>
          <Card
            header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
            message={alertMessage}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={updateDetails}
          />
        </Modal>
      )}
    </FormWrapper>
  </PortalContainer>)
}


SubscriptionButtons.propTypes = {
  data: PropTypes.node,
  selectedTab: PropTypes.string,
  handleSaveChanges: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
  isActiveButtonEnabled: PropTypes.bool,
  proceedToActive: PropTypes.bool,
  handleStopSubscriptionChange: PropTypes.func,
  handleCancelChanges: PropTypes.func,
  handleActiveTab: PropTypes.func,
  dataToBeUpdated: PropTypes.node,
  IsMobile: PropTypes.bool
};
SubscriptionButtons.defaultProps = {
  data: {},
  dataToBeUpdated: {},
  selectedTab:"",
  IsMobile: false,
  hasWriteAccess: false,
  isActiveButtonEnabled: false,
};
export default EditSubscription;
