import Routes from "./Routes";
import Colors from "../styles/Colors";
import Themes from "../styles/Theme";

// icons
import ArrowLeft from "../assets/Icons/BreadCrumbArrow.svg";
import UserSettingsIcon from "../assets/Icons/UserSettingsIcon.svg";
import EditIcon from "../assets/Icons/EditIcon.svg";
import EmailIcon from "../assets/Icons/MailIcon.svg";
import PhoneIcon from "../assets/Icons/PhoneIcon.svg";
import HomeIcon from "../assets/Icons/HouseIcon.svg";
import AddIcon from "../assets/Icons/AddIcon.svg";
import HashIcon from "../assets/Icons/HashIcon.svg";
import MinusIcon from "../assets/Icons/RemoveIcon.svg";
import ProfileIcon from "../assets/Icons/ProfileIcon.svg";
import WhiteProfileIcon from "../assets/Icons/WhiteProfileIcon.svg";
import AssignmentsMenuIcon from "../assets/Icons/AssignmentsMenuIcon.svg";
import DeleteIcon from "../assets/Icons/WhiteColorDeleteIcon.svg";
import SortIcon from "../assets/Icons/SortIcon.svg";
import AlphabetSort from "../assets/Icons/AlphabetSort.svg";
import TagIcon from "../assets/Icons/TagIcon.svg";
import ClockIcon from "../assets/Icons/ClockIcon.svg";
import DogPawIcon from "../assets/Icons/CustomersMenuIcon.svg";
import CartIcon from "../assets/Icons/PrimaryCart.svg";
import PawIcon from "../assets/Icons/DogPawIcon.svg";
import CubeIcon from "../assets/Icons/CubeIcon.svg";
import AZSortIcon from "../assets/Icons/AZSortIcon.svg";
import ZASortIcon from "../assets/Icons/ZASortIcon.svg";
import SortDownArrow from "../assets/Icons/SortDownArrow.svg";
import SortUpArrow from "../assets/Icons/SortUpArrow.svg";
import FemaleDog from "../assets/Images/FemaleDog.png";
import MaleDog from "../assets/Images/MaleDog.png";
import PrimaryDeleteIcon from "../assets/Icons/DeleteIcon.svg";
import PrimaryTickIcon from "../assets/Icons/GreenCircleTick.svg";
import ThumbIcon from "../assets/Icons/ThumbIcon.svg";
import BanIcon from "../assets/Icons/BanIcon.svg";
import BorderedThumbIcon from "../assets/Icons/WhiteThumbIcon.svg";
import WhiteBanIcon from "../assets/Icons/WhiteBanIcon.svg";
import WarningIcon from "../assets/Icons/Warning.svg";
import Marker from "../assets/customerFlowImages/Marker.svg";
import UpArrow from "../assets/customerFlowImages/UpArrow.svg";
import ArrowDown from "../assets/Icons/ArrowDown.svg";
import whitePriceTag from "../assets/customerFlowImages/whitePriceIcon.svg";
import DoneIcon from "../assets/Icons/Done.svg";
import ServiceLocation from "../assets/Icons/serviceLocation.svg";
import Cart from "../assets/customerFlowImages/cart.svg";
import menuBars from "../assets/customerFlowImages/menuBars.svg";
import Facebook from "../assets/customerFlowImages/facebook.svg";
import Instagram from "../assets/customerFlowImages/instagram.svg";
import LinkedIn from "../assets/customerFlowImages/linkedIn.svg";
import QuestionMarkIcon from "../assets/Icons/QuestionMark.svg";
import RefreshIcon from "../assets/Icons/Refresh.svg";
import PrimaryStopIcon from "../assets/Icons/Stop.svg";
import GreenIcon from "../assets/Icons/Green.svg";
import BronzeIcon from "../assets/Icons/Bronze.svg";
import SilverIcon from "../assets/Icons/Silver.svg";
import GoldIcon from "../assets/Icons/Gold.svg";
import CustomIcon from "../assets/Icons/Custom.svg";

// images
import PriceTag from "../assets/Icons/PriceTag.svg";
import WhiteEditIcon from "../assets/Icons/WhiteEditIcon.svg";
import SearchIcon from "../assets/Icons/SearchIcon.svg";
import EyeOpenIcon from "../assets/Icons/EyeOpenIcon.svg";
import EyeCloseIcon from "../assets/Icons/EyeCloseIcon.svg";
import CalendarAddIcon from "../assets/Icons/CalendarAddIcon.svg";
import ArrowRightWhite from "../assets/customerFlowImages/ArrowRightWhite.svg";
import TagSingleIcon from "../assets/customerFlowImages/tag.svg";
import FooterLogo from "../assets/customerFlowImages/footerLogo.svg";

import YellowPoint from "../assets/Icons/YellowPoint.svg";
import CalendarSelected from "../assets/Icons/CalendarSelected.svg";
import SelectedRadio from "../assets/Icons/SelectedRadio.svg";
import EmptyGreyCircle from "../assets/Icons/EmptyGreyCircle.svg";
import FlagIcon from "../assets/Icons/FlagIcon.svg";
import WhitePawIcon from "../assets/Icons/WhitePawIcon.svg";
import DogIcon from "../assets/Icons/DogIcon.svg";

// images
import Avatar from "../assets/Icons/Avtar.svg";
import PlusIcon from "../assets/Icons/PlusIcon.svg";
import EraseIcon from "../assets/Icons/EraseIcon.svg";
import CrossCircle from "../assets/Icons/CrossCircle.svg";
import Logo from "../assets/customerFlowImages/Logo.svg";

const language = require("../translations/sv.json");

const Constants = {
  ApplicationName: "MyDogCare",
  TitleDivder: " | ",
  language,
  en: "en",
  sv: "sv",
  routes: Routes,
  themes: Themes,
  stockholm: "Stockholm",
  storage: {
    token: "token",
    userData: "user",
    dataUser: "userData",
    roles: "roles",
    orgName: "MyDogCare Östermalm",
    cart: "cart",
  },
  requestType: {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
  },
  role: {
    Admin: "admin",
    Organization: "organization",
    Customer: "customer",
    "Service Provider": "service_provider",
    User: "organization_owner",
    Freelancer: "freelancer",
  },
  userRights: {
    edit: true,
    read: false,
    no_access: -1,
    manage: 2,
    1: { label: language.option_label_read_write, value: "edit" },
    0: { label: language.option_label_read, value: "read" },
    2: { label: language.option_manage_calendar, value: "manage" },
    "-1": { label: language.option_label_nothing, value: "no_access" },
    admin: { label: language.label_admin, value: "admin" },
    super_admin: { label: language.label_super_admin, value: "super_admin" },
    employee: { label: language.label_employee, value: "employee" },
    intern: { label: language.label_intern, value: "intern" },
    service_provider: { label: language.common_service_provider, value: "service_provider" },
    centre_manager: { label: language.label_centre_manager, value: "centre_manager" },
  },
  columnTranslations: {
    admin: language.label_admin,
    super_admin: language.label_super_admin,
    superadmin: language.label_super_admin,
    employee: language.label_employee,
    intern: language.label_intern,
    service_provider: language.common_service_provider,
    centre_manager: language.label_centre_manager,
    null: "-",
    undefined: "-",
  },
  adminUserAccess: {
    is_assignments_editable: 1,
    is_calendar_editable: 1,
    is_customer_editable: 1,
    is_customized: 0,
    is_dog_editable: 1,
    is_order_editable: 1,
    is_organization_editable: 1,
    is_room_editable: 1,
    is_services_editable: 1,
    is_users_editable: 1,
    is_subscription_editable:1,
    organization_id: "0f14810c-7899-4bec-932c-c1777b8146b7",
    rights: "admin",
    organization_name: "MyDogCare Östermalm",
  },
  HTTP_STATUS: { OK: 200, CREATED: 201, UNPROCESSABLE_ENTITY: 422 },
  fileSizeLimit: 10000000,
  phoneNumberLength: 10,
  PersonalNumberLength: 11,
  listItemTypes: {
    label: "label",
    packages: "packages",
    labelWithLetter: "labelWithLetter",
    image: "image",
    number: "number",
    text: "text",
    personalNumber: "personalNumber",
    price: "price",
    duration: "duration",
    applicationStatus: "applicationStatus",
    type: "type",
    roles: "roles",
    date: "date",
    multipleUser: "multipleUser",
    singleUser: "singleUser",
    organization: "organization",
    user: "user",
    dogHeight: "dogHeight"
  },
  types: {
    organization_owner: language.filter_label_user,
    service_provider: language.common_service_provider,
    freelancer: language.label_freelancer,
    admin: language.label_admin,
    super_admin: language.label_super_admin,
    employee: language.label_employee,
    intern: language.label_intern,
    centre_manager: language.label_centre_manager,
    new: language.status_label_new,
    active: language.status_label_active,
    banned: language.status_label_banned,
  },
  status: {
    banned: "banned",
    close: "close",
    new: "new",
    open: "open",
    cancelled: "cancelled",
    refunded: "refunded",
    cancelledRefunded: "cancelled-refunded",
    cancelledNoRefunded: "cancelled-no-refund",
  },
  acceptImageTypes: ["image/jpeg", "image/png", "image/jpg", ".jpg", ".jpeg", ".png"],
  sorting: {
    type: {
      ALPHABETS: "alphabet",
      ARROW: "arrow",
      ASC: "asc",
      DESC: "desc",
    },
    keys: {
      sortBy: "sortBy",
      sort: "sort",
    },
  },
  errorMessages: {
    serviceName: language.error_service_name_available,
    email: language.error_email_available,
    id: language.error_id_available,
    roomNotAvailable: "No space in room",
    bannedCustomer: "Banned Customer",
  },
  input: {
    name: {
      email: "email",
      password: "password",
      confirmPassword: "confirmPassword",
      firstName: "firstName",
      lastName: "lastName",
      id: "id",
      userType: "userType",
      phone: "phone",
      organisation: "organisation",
      profilePic: "profilePic",
      serviceName: "serviceName",
      category: "category",
      description: "description",
      priceXS: "priceXS",
      priceS: "priceS",
      priceM: "priceM",
      priceL: "priceL",
      fulldayservice: "fulldayservice",
      durationXS: "durationXS",
      durationS: "durationS",
      durationM: "durationM",
      durationL: "durationL",
      duration: "duration",
      name: "name",
      role: "role",
      price: "price",
      time: "time",
      selectedOrganisations: "selectedOrganisations",
      dogsLimit: "dogsLimit",
      size: "size",
      organisation_id: "organisation_id",
      rights: "rights",
      calendar: "calendar",
      room: "room",
      customer: "customer",
      dog: "dog",
      order: "order",
      services: "services",
      users: "users",
      assignment: "assignment",
      flat: "flat",
      house: "house",
      breed: "breed",
      age: "age",
      dog_size_weight: "dog_size_weight",
      dog_size_height: "dog_size_height",
      additionalInformation: "additionalInformation",
      reason: "reason",
      banReason: "banReason",
      male: "male",
      female: "female",
      place: "place",
      serviceProvider: "serviceProvider",
      client: "client",
      service: "service",
      schedule: "schedule",
      duplicate: "duplicate",
      isInsured: "isInsured",
      dogName: "dogName",
      dogBreed: "dogBreed",
      dogGender: "dogGender",
      sterilized: "sterilized",
      dogAge: "dogAge",
      dogDaycare: "dogDaycare",
      dogInsured: "dogInsured",
      moreInformation: "moreInformation",
      yourName: "yourName",
      telephone: "telephone",
      isSterilized: "isSterilized",
      gender: "gender",
      isAgreed: "isAgreed",
      serviceProviderOrgs: "serviceProviderOrgs",
      labelForNoAccess: "no_access",
      everyday: "everyday",
      startDate: "start_date",
      endDate: "end_date",
      subscription: "subscription",
      isNewToDayCare: "isNewToDayCare",
      bookingNoticeTime: "bookingNoticeTime",
      startEndDate: "startEndDate"
    },
    type: {
      text: "text",
      password: "password",
      number: "number",
    },
    format: {
      date: "YYYY-MM-DD",
      dayToYear: "DD-MM-YYYY",
      dotted: "DD.MM.YY",
      dottedFullYear: "DD.MM.YYYY",
      time: "HH:mm",
      twelveHourFormat: "hh:mm a",
      twentyFourHourFormat: "HH:mm",
      dateWithDay: "YYYY-MM-DD, dddd",
      yearToDay: "yyyy-MM-DD",
      dateWithTime: "YYYY-MM-DD HH:mm",
      dottedHourFormat: "HH.mm",
      twoDigitDateFormat: "YY-MM-DD"
    },
    param: {
      orgId: "organization_id",
      customerId: "customer_id",
      roomId: "room_id",
      serviceProviderId: "service_provider_id",
      userId: "user_id",
      serviceId: "service_id",
      organization: "organization",
      dogSize: "serviceable_dog_size",
    },
  },
  options: {
    UserOptions: [
      {
        label: language.label_freelancer,
        value: "freelancer",
      },
      {
        label: language.common_service_provider,
        value: "service_provider",
      },
    ],
    UserRoles: [
      { label: language.label_super_admin, value: "super_admin" },
      { label: language.label_admin, value: "admin" },
      { label: language.label_employee, value: "employee" },
      { label: language.label_intern, value: "intern" },
      { label: language.label_centre_manager, value: "centre_manager" },
      { label: language.common_service_provider, value: "service_provider" },
    ],
    Roles: ["admin", "super_admin", "employee", "intern", "centre_manager", "service_provider"],
    Rights: [
      { label: language.option_label_read_write, value: "edit" },
      { label: language.option_label_read, value: "read" },
      { label: language.option_label_nothing, value: "no_access" },
    ],
    SuperAdminRight: [{ label: language.option_manage_calendar, value: "manage" }],
    applicationStatus: [
      {label: language.label_emailed, value: "emailed"},
      {label: language.label_contract_sent, value: "contract-sent"},
      {label: language.label_signed, value: "signed"},
      {label: language.label_discussing, value: "discussing"},
      {label: language.declined, value: "declined"},
      {label: language.label_meeting_booked, value: "meeting-booked"},
      {label: language.label_not_interested, value: "not-interested"}
    ],
    dogsAge: [
      { label: language.dog_age_6_months, value: "0-6" },
      { label: language.dog_age_6_12_months, value: "6-12" },
      { label: language.dog_age_12_24_months, value: "12-24" },
      { label: language.dog_age_25_36_months, value: "25-36" },
      { label: language.dog_age_three_years, value: "3" },
      { label: language.dog_age_four_years, value: "4" },
      { label: language.dog_age_five_years, value: "5" },
      { label: language.dog_age_six_years, value: "6" },
      { label: language.dog_age_seven_years, value: "7" },
      { label: language.dog_age_eight_years, value: "8" },
      { label: language.dog_age_nine_years, value: "9" },
      { label: language.dog_age_ten_years, value: "10" },
    ],
    dogsWeight: [
      { label: language.size_dog_mini, value: "mini" },
      { label: language.size_dog_small, value: "small" },
      { label: language.size_dog_medium, value: "medium" },
      { label: language.size_dog_large, value: "large" },
    ],
    dogsHeight: [
      { label: language.height_dog_mini, value: "mini" },
      { label: language.height_dog_small, value: "small" },
      { label: language.height_dog_medium, value: "medium" },
      { label: language.height_dog_large, value: "large" },
    ],
    dogsUsedToDayCare: [
      { label: language.common_no, value: false },
      { label: language.common_yes_a_little, value: true },
    ],
  },
  filter: {
    components: {
      LETTERSELECT: "letterSelect",
      CHECKBOX: "checkBox",
      DATEPICKER: "datePicker",
    },
    options: {
      DOG_SIZE:  [
        {label: language.size_small, value: "small", className: "col-4"},
        {label: language.size_medium, value: "medium", className: "col-4"},
        {label: language.size_filter_dog_large, value: "large", className: "col-4"}
      ],
      DOG_HEIGHT:  [
        {label: language.label_height_filter_mini, value: "mini", className: "col-4"},
        {label: language.label_height_filter_small, value: "small", className: "col-4"},
        {label: language.label_height_filter_medium, value: "medium", className: "col-4"},
        {label: language.label_height_filter_large, value: "large", className: "col-4"}
      ],
      INFO_STATUS: [
        {label: language.label_emailed, value: "emailed", className: "col-4"},
        {label: language.label_contract_sent, value: "contract-sent", className: "col-4"},
        {label: language.label_signed, value: "signed", className: "col-4"},
        {label: language.label_discussing, value: "discussing", className: "col-4"},
        {label: language.declined, value: "declined", className: "col-4"},
        {label: language.label_meeting_booked, value: "meeting-booked", className: "col-4"},
        {label: language.label_not_interested, value: "not-interested", className: "col-4"}
      ],
      ORDER_STATUS: [
        { label: language.status_new, value: "new" },
        { label: language.status_open, value: "open" },
        { label: language.status_close, value: "close" },
        { label: language.status_cancelled, value: "cancelled" },
        { label: language.status_refunded, value: "refunded" },
        { label: language.status_cancelled_refund, value: "cancelled-refunded" },
        { label: language.status_cancelled_no_refund, value: "cancelled-no-refund" },
      ],
      WEEK_DAYS: [
        { label: language.week_monday, value: "monday" },
        { label: language.week_tuesday, value: "tuesday" },
        { label: language.week_wednesday, value: "wednesday" },
        { label: language.week_thursday, value: "thursday" },
        { label: language.week_friday, value: "friday" },
        { label: language.week_saturday, value: "saturday" },
        { label: language.week_sundays, value: "sunday" },
      ],
      CUSTOMER_STATUS: [
        { label: language.status_label_active, value: "active" },
        { label: language.status_new, value: "new" },
        { label: language.status_label_banned, value: "banned" },
      ],
      MOBILESCREENALPHABETS: [
        ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
        ["K", "L", "M", "N", "O", "P", "Q", "R", "S"],
        ["T", "U", "V", "W", "X", "Y", "Z"],
      ],
      ALPHABETS: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      DOG_GENDER:  [
        {label: language.label_gender_male, value: "male"},
        {label: language.label_gender_female, value: "female",}
      ],
    },
  },
  apiEndPoints: {
    endPointUrl: {
      PUBLIC: "orders",
      REGISTER: "register",
      FILTER: "filter",
      FILTERS: "filters",
      SAVE_MULTIPLE: "save-multiple",
      LOGIN: "login",
      LOGOUT: "logout",
      FORGOT_PASSWORD: "forgot-password",
      RESET_PASSWORD: "reset-password",
      ROOMS: "rooms",
      BAN_USER: "ban-customer",
      DOG: "dog",
      ORDERS: "orders",
      ORGANIZATIONS: "organizations",
      SERVICES: "services",
      USERS: "users",
      CLIENTS: "clients",
      CLOSE: "close",
      TOTAL: "total",
      ASSIGNMENTS: "assignments",
      SUBSCRIPTIONS: "subscriptions",
      SUBSCRIPTION: "subscription",
      DOGS: "dogs",
      SERVICEPROVIDERS: "service-providers",
      ADDITIONAL_OPTIONS: "addtional-options",
      DUPLICATE: "duplicate",
      CUSTOMERS: "customers",
      CATEGORIES: "categories",
      BOOKINGFLOW: "bookingFlow",
      SCHEDULE: "schedule",
      AVAILABLE_SLOTS: "available-slots",
      CHECKOUT: "checkout",
      FILES: "files",
      DOG_BREED: "dog-breed",
      INIT_KLARNA: "init-klarna",
      CART: "cart",
      EMAIL_VERIFY: "email/verify",
      CANCEL: "cancel",
      VERIFY: "verify",
      CONTACT_INFORMATION: "contact-information",
      AVAILABILITY: "slots/index",
      SLOTS: "slots",
      USER_SERVICES: "users/services",
      GET_SLOT_DATA: "slots/edit",
      UPDATE_SLOT_DATA: "slots/update",
      DELETE_AVAILABILITY: "slots/delete",
      DELETE_AVAILABILITY_DETAILS: "slots/get/assignments",
      REFUND: "refund",
      AVAILABLE_SLOTS_DATES: "available-slots-dates",
      GUEST_SUBSCRIPTION_ORDER:"guest-subscription",
      GUEST_ORDERS: "guest-orders"
    },
  },
  icons: {
    CustomIcon,
    GoldIcon,
    SilverIcon,
    BronzeIcon,
    GreenIcon,
    ArrowLeft,
    ArrowRightWhite,
    UserSettingsIcon,
    EmailIcon,
    PhoneIcon,
    HomeIcon,
    ProfileIcon,
    WhiteProfileIcon,
    HashIcon,
    AddIcon,
    MinusIcon,
    AssignmentsMenuIcon,
    DeleteIcon,
    DogPawIcon,
    CartIcon,
    AlphabetSort,
    SortIcon,
    TagIcon,
    ClockIcon,
    EditIcon,
    PawIcon,
    CubeIcon,
    AZSortIcon,
    ZASortIcon,
    SortDownArrow,
    SortUpArrow,
    FemaleDog,
    MaleDog,
    PrimaryDeleteIcon,
    PrimaryTickIcon,
    PrimaryStopIcon,
    ThumbIcon,
    BanIcon,
    WhiteBanIcon,
    BorderedThumbIcon,
    WarningIcon,
    Marker,
    UpArrow,
    ArrowDown,
    whitePriceTag,
    DoneIcon,
    ServiceLocation,
    Cart,
    menuBars,
    Facebook,
    Instagram,
    LinkedIn,
    PriceTag,
    WhiteEditIcon,
    SearchIcon,
    EyeOpenIcon,
    EyeCloseIcon,
    CalendarAddIcon,
    Avatar,
    PlusIcon,
    EraseIcon,
    CrossCircle,
    QuestionMarkIcon,
    RefreshIcon,
    YellowPoint,
    CalendarSelected,
    SelectedRadio,
    EmptyGreyCircle,
    FlagIcon,
    WhitePawIcon,
    DogIcon
  },
  images: {
    PriceTag,
    WhiteEditIcon,
    SearchIcon,
    EyeOpenIcon,
    EyeCloseIcon,
    CalendarAddIcon,
    Avatar,
    PlusIcon,
    EraseIcon,
    CrossCircle,
    TagSingleIcon,
    Logo,
    FooterLogo,
  },
  translations: {
    male: language.label_gender_male,
    female: language.label_gender_female,
    yes: language.common_yes_a_little,
    no: language.common_no,
    0: language.common_no,
    "0-6": language.dog_age_6_months,
    "6-12": language.dog_age_6_12_months,
    "12-24": language.dog_age_12_24_months,
    "25-36": language.dog_age_25_36_months,
    3: language.dog_age_three_years,
    4: language.dog_age_four_years,
    5: language.dog_age_five_years,
    6: language.dog_age_six_years,
    7: language.dog_age_seven_years,
    8: language.dog_age_eight_years,
    9: language.dog_age_nine_years,
    10: language.dog_age_ten_years,
    mini: language.size_dog_mini,
    small: language.size_dog_small,
    medium: language.size_dog_medium,
    large: language.size_dog_large,
    time: language.header_available_time_slots,
    startEndDate: language.header_available_time_slots,
    duplicate: language.header_available_time_slots,
    start_date: language.header_start_date,
    end_date: language.header_end_date,
    place: language.subheader_list_places,
    room: language.subheader_list_rooms,
    service: language.subheader_list_services,
    serviceProvider: language.subheader_list_service_providers,
    dog: language.subheader_list_dogs,
    Monday: language.label_monday,
    Tuesday: language.label_tuesday,
    Wednesday: language.label_wednesday,
    Thursday: language.label_thursday,
    Friday: language.label_friday,
    Saturday: language.label_saturday,
    Sunday: language.label_sunday,
    monday: language.label_monday,
    tuesday: language.label_tuesday,
    wednesday: language.label_wednesday,
    thursday: language.label_thursday,
    söndag : language.label_sunday,
    måndag : language.label_monday,
    tisdag : language.label_tuesday,
    torsdag : language.label_thursday,
    onsdag : language.label_wednesday,
    fredag : language.label_friday,
    lördag : language.label_saturday,
    friday: language.label_friday,
    saturday: language.label_saturday,
    sunday: language.label_sunday,
  },
  assignmentHeaderTranslations: {
    place: language.header_place,
    client: language.header_client,
    service: language.header_service_modal,
    room: language.header_room,
    serviceProvider: language.header_serviceProvider,
    dog: language.header_dog,
    duplicate: language.header_duplicate,
    time: language.header_time,
    startEndDate: language.header_time,
    schedule: language.header_schedule,
    addDog: language.header_addDog,
    close: language.status_close,
    new: language.status_new,
    open: language.status_open,
    cancelled: language.status_cancelled,
    mini: language.size_mini,
    medium: language.size_medium,
    small: language.size_small,
    large: language.size_large,
    "not-paid": language.status_not_paid,
    refunded: language.status_refunded,
    "cancelled-refunded": language.status_cancelled_refund,
    "cancelled-no-refund": language.status_cancelled_no_refund,
    "application": language.label_application,
    "stopped": language.stopped,
    "active": language.label_active
  },
  orderHeaderTranslations: {
    place: language.header_change_place,
    client: language.header_client,
    service: language.header_choose_Service,
    room: language.header_change_room,
    serviceProvider: language.header_change_service_provider,
    dog: language.header_choose_dog,
    time: language.header_time,
    startEndDate: language.header_time,
    addDog: language.header_addDog,
    description: language.header_description,
  },
  subscriptionHeaderTranslations: {
    place: language.header_place,
    start_date: language.edit_date,
    end_date: language.edit_date
  },
  packageTranslations: {
    bronze: language.bronze,
    silver: language.silver,
    gold: language.gold,
    custom: language.custom
  },
  labelColor: {
    mini: Colors.DarkGrey,
    medium: Colors.Orange,
    small: Colors.Pink,
    large: Colors.Blue,
    new: Colors.Blue,
    open: Colors.Orange,
    close: Colors.Primary,
    cancelled: Colors.Red,
    refunded: Colors.Primary,
    "cancelled-refunded": Colors.LightGrey,
    "cancelled-no-refund": Colors.LightGrey,
    "active": Colors.Orange,
    "stopped": Colors.LightGrey,
    "application": Colors.LightGrey,
  },
  calendar: {
    dayGridMonth: "dayGridMonth",
    timeGridDay: "timeGridDay",
    timeGridWeek: "timeGridWeek",
    desktop: "desktop",
    mobile: "mobile",
    twoDigit: "2-digit",
    day: "day",
    title: "title",
    slotDuration: "00:60:00",
    scrollTime: "07:00:00",
    card: "card",
    dateFormats: {
      dottedMonthDate: "MM.DD",
      apiQueryFormat: "yyyy-LL-dd",
    },
    shortMeridiem: "short",
    fm: "fm",
    am: "AM",
    pm: "PM",
    em: "em",
    calendarStartDate: "startDate",
    calendarEndDate: "endDate",
    slotStartDate: "start_date",
    slotEndDate: "end_date",
    start: "start",
    available: "available",
    adminAvailable: "adminAvailable",
  },
  number: {
    one: "1",
    two: "2",
  },
  adminRoutes: {
    adminPrefix: "/admin",
    assignments: "assignments",
    subscription: "subscription",
    organization: "organization",
    customers: "customers",
    orders: "orders",
    rooms: "rooms",
    services: "services",
    users: "users",
    editSubscription: "edit-subscription",
    editAssignment: "edit-assignment",
    editOrganization: "edit-organization",
    editCustomer: "edit-customer",
    editOrder: "edit-order",
    addDog: "add-dog",
    editRoom: "edit-room",
    editService: "edit-service",
    editUser: "edit-user",
  },
  currency: {
    sek: "SEK",
  },
  slot: {
    start: "10:00",
    end: "22:00",
  },
  phone_number_placeholder: "xxx xxxxxx",
  SUPER_ADMIN: "super_admin",
  SERVICE_PROVIDER: "service_provider",
  UNAUTHORIZED: "unauthorized",
  paymentMethods: {
    KLARNA: "klarna",
  },
  dogSize: {
    mini: "mini",
    small: "small",
    medium: "medium",
    large: "large",
  },
  subscriptionStatus: {
    application: "open",
    canceled: "canceled",
    active: "active"
  },
  subscriptionPackage: {
    bronze: "Brons",
    silver: "Silver",
    gold: "Guld",
    custom: "Custom"
  },
  subscriptionIconAndColor: {
    bronze: [
      {
        icon: GreenIcon,
        color: Colors.Green,
      },
      {
        icon: BronzeIcon,
        color: Colors.Beige,
      },
    ],
    silver: {
      icon: SilverIcon,
      color: Colors.LightGrey,
    },
    gold: {
      icon: GoldIcon,
      color: Colors.Orange,
    },
    custom: {
      icon: CustomIcon,
      color: Colors.Blue,
    },
  },
  applicationStatus: {
    emailed: language.label_emailed,
    "contract-sent": language.label_contract_sent,
    signed: language.label_signed,
    discussing: language.label_discussing,
    declined: language.declined,
    "meeting-booked": language.label_meeting_booked,
    "not-interested": language.label_not_interested
  },
  packagesTranslations: {
    "Bronspaketet": language.bronze,
    "Silverpaketet": language.silver,
    "Guldpaketet": language.gold,
    "Custom": language.custom
  },
  READ:0,
  WRITE:1,
  MANAGE:2,
  NO_ACCESS:-1,
  MY_DOG_CARE_URL: "https://mydogcare.com/tjanster",
  PRIVACY_POLICY: "personuppgiftspolicy",
  WEEK_DAYS: {
    söndag : "Sunday",
    måndag : "Monday",
    tisdag : "Tuesday",
    torsdag : "Thursday",
    onsdag : "Wednesday",
    fredag : "Friday",
    lördag : "Saturday",
  },
  weightTranslations: {
    mini: language.size_dog_mini,
    small: language.size_dog_small,
    medium: language.size_dog_medium,
    large: language.size_dog_large,
  },
  heightTranslations: {
    mini: language.height_dog_mini,
    small: language.height_dog_small,
    medium: language.height_dog_medium,
    large: language.height_dog_large,
  },
  CUSTOM_ERROR_CODE: {
    BANNED_CUSTOMER: "E0001",
    SERVICE_PROVIDER_HAS_BOOKING: "E0002",
    SERVICE_PROVIDER_HAS_SLOTS: "E0007",
    SERVICE_AND_AVAILABLE_SLOT_NOT_RELATED: "E0009",
  },
  defaultApplicationStatus: ["emailed", "contract-sent", "signed", "discussing", "meeting-booked"],
  CSV_FILENAME:{
    CUSTOMERS: "Customers",
    SUBSCRIPTION: "Subscription",
  },
  SUBSCRIPTION_SORT_FIELDS: {
    dog: "dogs.name",
    organizations: "organizations.name",
    product: "services.name",
    dog_size: "dogs.dog_size_height",
    application_status: "application_status",
    created_at: "created_at",
  },
  SUBSCRIPTION_FILTER_KEYS: {
    dog_size: "whereHas[dog][whereIn][dog_size_height]",
    subscription_days: "subscription_days",
    gender: "whereHas[dog][whereIn][gender]",
  }
};
export default Constants;
