import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Constants from "../../shared/Constants";
import Slider from "../Slider/Slider";
import {
  Ul, IsDesktop, IsMobile, Div,
} from "../../styles/Styles";
import {
  MenuItem, SideMenuContainer, MenuContainer, Item, MenuLabel,
} from "./Styles";
import {
  Logo,
  CalendarMenuIcon,
  UserMenuIcon,
  ServicesMenuIcon,
  AssignmentsMenuIcon,
  OrdersMenuIcon,
  CustomersMenuIcon,
  RoomsMenuIcon,
  CrossInCirle,
  SubscriptionsIcon
} from "../../styles/Icons";
import AuthService from "../../services/AuthService";
import Config from "../../config";
import { loggedInUser, fetchDogBreeds } from "../../store/actions/AppAction";
import Label from "../Label/Label";
import { getFilters } from "../../store/actions/FilterAction";

export const Sidemenu = ({
  open, handleCloseMenu,
}) => {
  const authService = new AuthService(Config.apiBase);
  const [data, setData] = useState({});
  const isActive = (route) => (window.location.pathname.search(route) >= 0 ? "active" : "");
  const { routes } = Constants;
  const dispatch = useDispatch();
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));
  const getDogBreeds = () => dispatch(fetchDogBreeds());
  const getAllFilters = () => dispatch(getFilters());
  const userDetails = useSelector((state) => state.AppReducer.userDetails);
  const userToken = useSelector((state) => state.AppReducer.userToken);
  const { openAssignments = 0, openOrders = 0, newCustomers = 0, newSubscription = 0 } = userDetails || {};
  const getDetails = () => {
    try {
      authService.usersDetails().then((response) => {
        getLoginUser(response);
        const { user_access: userAccess = {} } = response;
        setData(userAccess);
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDogBreeds();
    getAllFilters();
    if (userDetails === {} || userToken === "") {
      getDetails();
    } else {
      setData(userDetails && userDetails.user_access);
    }
  }, []);

  const {
    module={},
  } = data;
  const renderSidemenu = (onClose = () => {}) => (
    <SideMenuContainer id="sidebar">
      <IsDesktop mx={3} mt={3} mb={4}>
        <a href aria-label="myDogCare" target="_self">
          <Logo />
        </a>
      </IsDesktop>
      <IsMobile>
        <MenuItem>
          <MenuLabel fontSize="18px" justifyContent="space-between">
            <Item className="p-0">
              <FormattedMessage id="common_menu" defaultMessage="Menu" />
            </Item>
            <Item>
              <CrossInCirle onClick={handleCloseMenu} />
            </Item>
          </MenuLabel>
        </MenuItem>
      </IsMobile>
      <MenuContainer>
        {data && (
          <Ul p="0px" m="0px">
            {module.order && (
              <Link to={routes.ordersList.url} className="text-decoration-none">
                <MenuItem
                  active={isActive(routes.ordersList.url) || isActive(routes.addOrder.url)}
                  onClick={onClose}
                >
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center"><OrdersMenuIcon /></Div>
                    <Item>
                      <FormattedMessage id="common_orders" defaultMessage="Orders" />
                    </Item>
                    {openOrders ? (
                      <Item ml={2}>
                        <Label value={openOrders} />
                      </Item>
                    )
                      : ""}
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
            {module.room && (
              <Link to={routes.roomsList.url} className="text-decoration-none">
                <MenuItem
                  active={isActive(routes.roomsList.url) || isActive(routes.addRoom.url)}
                  onClick={onClose}
                >
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center">
                      <RoomsMenuIcon />
                    </Div>
                    <Item>
                      <FormattedMessage id="common_rooms" defaultMessage="Rooms" />
                    </Item>
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
            {module.customer && (
              <Link to={routes.customersList.url} className="text-decoration-none">
                <MenuItem
                  active={isActive(routes.customersList.url)
              || isActive(routes.addCustomer.url)}
                  onClick={onClose}
                >
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center">
                      <CustomersMenuIcon />
                    </Div>
                    <Item>
                      <FormattedMessage id="common_customers" defaultMessage="Customers" />
                    </Item>
                    {newCustomers ? (
                      <Item ml={2}>
                        <Label value={newCustomers} />
                      </Item>
                    )
                      : ""}
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
            {module.subscriptions && (
              <Link to={routes.subscriptionsList.url} className="text-decoration-none">
                <MenuItem
                  active={
                    isActive(routes.subscriptionsList.url)
              || isActive(routes.addSubscriptions.url)
              || isActive(routes.editSubscriptions.url)
              || isActive(routes.adminAddSubscriptions.url)
                  }
                  onClick={onClose}
                >
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center">
                      <SubscriptionsIcon />
                    </Div>
                    <Item>
                      <FormattedMessage id="common_subscriptions" defaultMessage="Subscriptions" />
                    </Item>
                    {!!newSubscription && <Item ml={2}>
                      <Label value={newSubscription} />
                    </Item>}
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
            {module.assignments && (
              <Link to={routes.assignmentsList.url} className="text-decoration-none">
                <MenuItem
                  active={
                    isActive(routes.assignmentsList.url)
              || isActive(routes.addAssignment.url)
              || isActive(routes.editAssignment.url)
                  }
                  onClick={onClose}
                >
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center">
                      <AssignmentsMenuIcon />
                    </Div>
                    <Item>
                      <FormattedMessage id="common_assignments" defaultMessage="Assignments" />
                    </Item>
                    {openAssignments ? (
                      <Item ml={2}>
                        <Label value={openAssignments} />
                      </Item>
                    )
                      : ""}
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
            {module.services && (
              <Link to={routes.servicesList.url} className="text-decoration-none">
                <MenuItem
                  active={
                    isActive(routes.servicesList.url)
                || isActive(routes.addService.url) || isActive(routes.editService.url)
                  }
                  onClick={onClose}
                >
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center">
                      <ServicesMenuIcon />
                    </Div>
                    <Item>
                      <FormattedMessage id="common_services" defaultMessage="Services" />
                    </Item>
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
            {module.calendar && (
              <Link to={routes.calendar.url} className="text-decoration-none">
                <MenuItem active={isActive(routes.calendar.url)} onClick={onClose}>
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center">
                      <CalendarMenuIcon />
                    </Div>
                    <Item>
                      <FormattedMessage id="common_calendar" defaultMessage="Calendar" />
                    </Item>
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
            {module.users && (
              <Link to={routes.usersList.url} className="text-decoration-none">
                <MenuItem
                  active={isActive(routes.usersList.url)
                || isActive(routes.addUser.url) || isActive(routes.editUser.url)}
                  onClick={onClose}
                >
                  <MenuLabel>
                    <Div width="25px !important" display="flex" alignSelf="center">
                      <UserMenuIcon />
                    </Div>
                    <Item>
                      <FormattedMessage id="common_users" defaultMessage="Users" />
                    </Item>
                  </MenuLabel>
                </MenuItem>
              </Link>
            )}
          </Ul>
        )}
      </MenuContainer>
    </SideMenuContainer>
  );
  return (
    <>
      <IsDesktop>{renderSidemenu()}</IsDesktop>
      {open && (
        <IsMobile>
          <Slider open={open} closeSlider={handleCloseMenu}>
            {renderSidemenu(handleCloseMenu)}
          </Slider>
        </IsMobile>
      )}
    </>
  );
};

Sidemenu.propTypes = {
  /**
   * Do you want to open the menu bar in mobile screen..
   */
  open: PropTypes.bool,
  /**
   * Closes side menu
   */
  handleCloseMenu: PropTypes.func,
};
Sidemenu.defaultProps = {
  open: false,
  handleCloseMenu: undefined,
};

export default withRouter(Sidemenu);
