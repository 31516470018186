import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Colors from "../../styles/Colors";
import { GreenClrLeftArrow, GreenClrRightArrow } from "../../styles/Icons";
import {
  Tabcontainer, Tab, MDLabel, IsDesktop, IsMobile, MDLabelWrapper, Span,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";
import Label from "../Label/Label";

const { language, themes: { colors } } = Constants;

const Tabs = ({
  tabs, onClick, selectedTab, onNext, onPrevious, openOrders, showTabArrowsOn = 2
}) => {
  const tabIndex = tabs.findIndex((obj) => obj === selectedTab);
  const orders = [language.common_orders, language.common_assignments, language.label_application];

  const handleOnClick = useCallback((tab)=> () => {
    onClick(tab);
  });

  return (
    <>
      <IsDesktop>
        <Tabcontainer>
          {tabs.map((tab) => (
            <Tab active={selectedTab === tab} onClick={handleOnClick(tab)} key={tab}>
              <MDLabel display="inline-block" fontSize="16px" cursor="pointer" textAlign="center">
                {tab}
                {orders.includes(tab) && openOrders ? (
                  <Span ml={2}>
                    <Label value={openOrders} bg={selectedTab === tab ? colors.white : ""} />
                  </Span>
                ) : ""}
              </MDLabel>
            </Tab>
          ))}
        </Tabcontainer>
      </IsDesktop>
      <IsMobile>
        {tabs && tabs.length > showTabArrowsOn
          ? (
            <Tabcontainer bg={Colors.Transparent} display="flex" justifyContent="space-between" width="100%">
              <MDLabelWrapper height="40px" width="40px" backgroundColor={Colors.White} onClick={() => onPrevious(tabIndex - 1)} className={tabIndex === 0 ? "disabled-state mr-3" : "mr-3"}>
                <GreenClrLeftArrow />
              </MDLabelWrapper>
              <Tab active={selectedTab}>
                <MDLabel display="inline-block" fontSize="16px" cursor="pointer" textAlign="center">
                  {selectedTab}
                  {orders.includes(selectedTab) && openOrders ? (
                    <Span ml={2}>
                      <Label value={openOrders} bg={colors.white} />
                    </Span>
                  ) : ""}
                </MDLabel>
              </Tab>
              <MDLabelWrapper height="40px" width="40px" backgroundColor={Colors.White} onClick={() => onNext(tabIndex + 1)} className={tabIndex === tabs.length - 1 ? "disabled-state ml-3" : "ml-3"}>
                <GreenClrRightArrow />
              </MDLabelWrapper>
            </Tabcontainer>
          )
          : (
            <Tabcontainer>
              {tabs.map((tab) => (
                <Tab active={selectedTab === tab} onClick={handleOnClick(tab)} key={tab}>
                  <MDLabel display="inline-block" fontSize="16px" cursor="pointer" textAlign="center">
                    {tab}
                    {orders.includes(tab) && openOrders ? (
                      <Span ml={2}>
                        <Label value={openOrders} bg={selectedTab === tab ? colors.white : ""} />
                      </Span>
                    ) : ""}
                  </MDLabel>
                </Tab>
              ))}
            </Tabcontainer>
          )}
      </IsMobile>
    </>
  );
};

Tabs.propTypes = {
  /**
   * List out the tabs
   */
  tabs: PropTypes.node,
  /**
   * Name of the selected tab
   */
  selectedTab: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Move to next tab
   */
  onNext: PropTypes.func,
  /**
   * Move to previous tab
   */
  onPrevious: PropTypes.func,
  /**
   * Count of open orders
   */
  openOrders: PropTypes.number,

  /**
   * Count for showing arrow
   */
  showTabArrowsOn: PropTypes.number
};

Tabs.defaultProps = {
  tabs: [],
  selectedTab: null,
  onClick: undefined,
  onNext: undefined,
  onPrevious: undefined,
  openOrders: 0,
  showTabArrowsOn: 2
};

export default Tabs;
